<template>
  <v-container pa-0 ma-0 fluid>
    <!-- <v-layout> -->
      <!-- {{screenSize}}
      {{WidthSize}} -->
        <v-layout align-center justify-center v-if="loadingLogs">
          <a-spin class="mt-2" tip="Loading..." />
        </v-layout>
    <v-row no-gutters v-if="!loadingLogs">
      <v-col  :sm="12" :xs="12" :md="6">
        <v-card
          :class="seeDetails ? 'pa-1 ml-8 mt-1' : 'mx-auto text-center'"
          style="background-color: #0f3057; width:90%"
          dark
          
        >
          <v-card-text>
            <v-sheet color="rgba(0, 0, 0, .12)">
              <barchart
                v-if="isLoadBar"
               style="width:100%;"
               :height="isTablet?screenSize-650:SizeName=='xl'? screenSize-600 : screenSize - 370"
                :data="chartData"
                :options="chartOptions"
              ></barchart>
            </v-sheet>
          </v-card-text>

          <v-card-text>
            <div class="display-1 font-weight-thin">Keyword Logs</div>
          </v-card-text>

          <v-divider></v-divider>
        </v-card>
      </v-col>
      <v-col >
        <v-layout align-center justify-center row wrap>
        <a-input  style="width: 80%" addon-before="Search" v-model="search" class="mb-1 mt-1"></a-input>
        <a-table
          
          style="width: 80%"
          :data-source="logsPaginatedData"
          :columns="header"
          bordered
          :scroll="{ y: screenSize - 230 }"
          :pagination="false"
        >
        </a-table>
        </v-layout>
      </v-col>
      <v-col :sm="12" :xs="12" :md="6"> </v-col>
      <v-col cols="6">
        <v-pagination
          v-model="LogsPageNo"
          :length="logsPageCount"
          :total-visible="5"
          dark
          prev-icon="mdi-menu-left"
          next-icon="mdi-menu-right"
          v-if="!loadingLogs"
        ></v-pagination>    
    </v-col>
    </v-row>
 
    <!-- </v-layout> -->
  </v-container>
</template>
<script>
import barchart from "../barchart.js";

import axios from "axios";
const header = [
  {
    title: "#",
    dataIndex: "num",
    key: "num",
    width:60
  },
  {
    title: "Keyword",
    dataIndex: "searchWord",
    key: "searchWord",
  },
  {
    title: "Total Logs",
    dataIndex: "count",
    key: "count",
    width:110
  },
];
export default {
  components: {
    barchart,
  },
  data: () => ({
    loadingLogs: true,
    LogsPageNo: 1,
    search:'',
    rowLogsCount: 30,
    header,
    isLoadBar: false,
    Logs: [{ searchWord: "", count: 0 }],
    labels: [],
    value: [],
    seeDetails: false,
    AllLogs: [],
    chartOptions: {
      hoverBorderWidth: 20,
       scales: {
      yAxes: [{
        ticks: {
          beginAtZero: true
        }
      }]
    }
    },
    chartData: {
      hoverBackgroundColor: "red",
      hoverBorderWidth: 10,
      labels: [],
      maintainAspectRatio: false,
      datasets: [
        {
          label: "Logs",
          backgroundColor: [],
          data: [],
          responsive: true,
          maintainAspectRatio: false,
         
        },
      ],
     
    },
  }),
  created() {
    // console.log()
    this.loadLogs();
  },
  methods: {
    getRandomColor() {
      // var letters = '0123456789ABCDEF';
      // var color = '#';
      // for (var i = 0; i < 6; i++) {
      //   color += letters[Math.floor(Math.random() * 16)];
      // }
      const hue = Math.floor(Math.random() * 360);
      const randomColor = `hsl(${hue},70%,80%)`;
      return randomColor;
    },
    loadLogs() {
      axios.defaults.headers.common["x-api-key"] =
        "H8Pt5n6cbFk95UiXQjwZ9cwu6mcZqfF2rTw3HeS3";
      let url = `${this.api}getAllLogs`;
      axios.get(url).then((res) => {
        res.data.sort((a, b) => (a.count < b.count ? 1 : -1));
        let graphData = res.data
        // graphData = graphData.splice(0,8)
        let bardata = {};
        bardata.label = "Logs";
        bardata.backgroundColor = [];
        bardata.data = [];
        graphData.map((rec,i) => {
          if(i <=8){
          bardata.backgroundColor.push(this.getRandomColor());
          bardata.data.push(rec.count);
          bardata.responsive = true;
          bardata.maintainAspectRatio = false;
          bardata.pieceLabel = {};
          bardata.pieceLabel["mode"] = "percentage";
          bardata.pieceLabel["precision"] = 1;
          this.chartData.labels.push(rec.searchWord);
          }
        });
          bardata.data.push(0);

        this.chartData.datasets[0] = bardata;
        // console.log(this.chartData);
        this.AllLogs = res.data.map((r, i) => {
          r.key = i;
          return r;
        });
        
        this.AllLogs.map((r, i) => {
          r.num = i + 1;
          return r;
        });
        res.data = res.data.filter((r) => {
          return r.count != 1;
        });
        res.data.sort((a, b) => (a.count < b.count ? 1 : -1));

        this.Logs = res.data.sort().splice(0, 5);
        this.Logs.map((r) => {
          this.labels.push(r.searchWord);
          this.value.push(r.count);
        });
        this.isLoadBar = true;
        this.loadingLogs = false
      });
    },
  },
  computed: {
    logsPageCount() {
      let l = this.filteredLogs.length,
        s = this.rowLogsCount;
      return Math.ceil(l / s);
    },
     logsPaginatedData() {
      const start = (this.LogsPageNo - 1) * this.rowLogsCount,
        end = start + this.rowLogsCount;
      return this.filteredLogs.slice(start, end);
    },
    SizeName(){
       return this.$vuetify.breakpoint.name;
    },
    isTablet(){
      if(this.$vuetify.breakpoint.name == 'sm' || this.$vuetify.breakpoint.name == 'xs'){
        return true
      }
      else return false
    },
    WidthSize() {
      return this.$vuetify.breakpoint.width;
    },
    screenSize() {
      return this.$vuetify.breakpoint.height;
    },
    filteredLogs(){
      if(this.search){
        return this.AllLogs.filter(rec=>{
          let search = new RegExp(this.search.toUpperCase(), "g");
          return JSON.stringify(Object.values(rec)).toUpperCase().match(search);
        })
      }
      else return this.AllLogs
    }
  },
};
</script>

<style>
.v-sheet--offset {
  top: -24px;
  position: relative;
}
</style>